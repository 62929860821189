<template>
  <div
    class="collapse"
    :class="{
      [`collapse_${direction}`]: true,
      collapse_show: cIsFullVisible || show,
    }"
  >
    <div
      class="collapse__content-wrap"
      :style="cStyle"
    >
      <div
        ref="content"
        class="collapse__content"
      >
        <slot name="content" />
        <div
          v-if="fadeColor"
          class="collapse__fade"
          :style="cFadeStyle"
        />
      </div>
    </div>
    <div
      v-if="cShowToggle"
      class="collapse__toggle"
      @click.stop.prevent="toggle"
    >
      <slot :is-show="show" />
      <!--            <i v-if="!disabled && caret"-->
      <!--               class="icon-arrow-down collapse__caret"-->
      <!--               :class="{collapse__caret_show: show}"-->
      <!--            />-->
      <template v-if="!disabled && caret">
        <Plus
          v-if="show"
          :color="iconColors.primary"
        />
        <Minus
          v-else
          :color="iconColors.primary"
        />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Plus, Minus } from 'lucide-vue-next';
import { computed, ref, onMounted } from 'vue';
import { iconColors } from 'assets/js/utils';

const props = defineProps({
  visibleHeight: {
    type: [Number, String],
    default: 0,
  },
  direction: {
    type: String,
    default: 'bottom',
  },
  initialOpen: {
    type: Boolean,
    default: false,
  },
  caret: {
    type: Boolean,
    default: true,
  },
  alwaysShowToggle: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  fadeColor: {
    type: String,
    default: null,
  },
});
const show = ref(props.initialOpen);
const contentHeight = ref(null);
const animationCompleted = ref(props.initialOpen);
const content = ref(null);

onMounted(() => {
  calculateHeight();
});

const cStyle = computed(() => {
  if (animationCompleted.value) {
    return { maxHeight: show.value ? 'unset' : props.visibleHeight + 'px' };
  } else {
    return { maxHeight: show.value ? contentHeight.value + 'px' : props.visibleHeight + 'px' };
  }
});
const cIsFullVisible = computed(() => {
  return props.visibleHeight >= contentHeight.value;
});
const cShowToggle = computed(() => {
  return props.alwaysShowToggle || !cIsFullVisible.value;
});
const cFadeStyle = computed(() => {
  return {
    background: `linear-gradient(0, ${props.fadeColor}, transparent)`,
  };
});

function calculateHeight() {
  contentHeight.value = content.value.scrollHeight;
}

function toggle() {
  if (props.disabled) {
    return;
  }
  calculateHeight();
  animationCompleted.value = false;
  setTimeout(() => {
    show.value = !show.value;

    setTimeout(() => {
      animationCompleted.value = true;
    }, 300);
  }, 0);
}
</script>

<style scoped lang="scss">
.collapse {
  display: flex;
  flex-direction: column;
}

.collapse_bottom {
  .collapse__toggle {
    order: -1;
  }
}

.collapse__content-wrap {
  overflow: hidden;
  transition: max-height 0.3s;
  position: relative;
}

.collapse__toggle {
  cursor: pointer;
  display: grid;
  grid-template-columns: auto max-content;
  align-items: center;
  gap: 5px;
}

.collapse__caret {
  transition: transform 0.3s ease;
  display: inline-block;
  font-weight: 900;
  color: $dark;
  margin-left: 5px;
}

.collapse__caret_show {
  transform-origin: center;
  transform: rotate(180deg);
}

.collapse__fade {
  position: absolute;
  bottom: 0;
  height: 30px;
  width: 100%;
  pointer-events: none;
}

.collapse_show {
  .collapse__fade {
    opacity: 0;
  }
}
</style>
